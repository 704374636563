<template>
  <main class="DropoffsExport">
    <TheSubHeader
      icon="export-alt"
      title="Exportar Dropoffs"
      page-title-tag="Dropoff Export"
      type="filter"
      :actions="['back']"
      organizer-gap="1rem"
      organizer-columns="240px repeat(4, 1fr) 140px"
    >
      <template #filter>
        <!-- Period -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="Data"
          :max-range="15"
          :not-after="todayParsed"
          :clearable="false"
          @input="resetSearch()"
        />
        <!-- Stores -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filters.stores"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione"
          multiple
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="resetSearch()"
        />
        <!-- Drivers -->
        <BaseTreeSelect
          v-model="filters.drivers"
          name="Motorista"
          label="Motorista"
          placeholder="Selecione"
          multiple
          suggestions="searchDrivers"
          @input="resetSearch()"
        />
        <!-- Orders -->
        <BaseTreeSelect
          v-model="filters.orders"
          name="Pedidos"
          label="Pedidos"
          placeholder="Nº do pedido"
          multiple
          enable-paste
          :select-all="false"
          :allow-create="true"
          :disable-branch-nodes="true"
          @input="resetSearch()"
        />
        <!-- Sellers -->
        <BaseTreeSelect
          v-model="filters.sellers"
          name="Sellers"
          label="Sellers"
          placeholder="Pesquisar"
          multiple
          :enable-paste="false"
          :min-search-length="2"
          suggestions="searchSellers"
          @input="resetSearch()"
        />

        <!-- Export -->
        <BaseButton
          :disabled="exportDisabled"
          label="Exportar" :class="internStatus" theme="simple" filled color="primary-base" text-color="white" @click="exportReport"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <div class="DropoffsExport__content">
      <h1 class="DropoffsExport__Title">
        Histórico de exportação
      </h1>
      <div class="DropoffsExport__table">
        <DataTable
          is-sticky
          :header="dropoffsReportsRequestedWithLoading.data.headers"
          :table="dropoffsReportsRequestedWithLoading.data.content"
          @download="download"
          @seeErrors="showDefaultErrorMessage"
        />
        <Pagination @page="fetchDropoffsReports()" @paginate="fetchDropoffsReports()" />
      </div>
    </div>
  </main>
</template>

<script>
import { TheSubHeader } from '@/components/organisms'
import { BaseTreeSelect, BaseButton, BaseDatePicker } from '@/components/atoms'
import { Pagination, DataTable, FiltersTag } from '@/components/molecules'
import { dropoff } from '@/api'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'DropoffsExport',
  components: {
    TheSubHeader,
    BaseDatePicker,
    BaseTreeSelect,
    DataTable,
    Pagination,
    BaseButton,
    FiltersTag
  },

  data(){
    return {
      filters: {
        dateRange: {
          init: null,
          end: null,
        },
        stores: [],
        drivers: [],
        orders: [],
        sellers: []
      },
      allTags: [],
      timeOutUpdate: 10 * 1000,
      time: null,
      updating: false
    }
  },
  computed:{
    ...mapState({
      dropoffsReportsRequested: state => state.dropoff.dropoffsReportsRequested,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),

    ...mapGetters({
      dropoffsReportsRequestedWithLoading: 'dropoff/dropoffsReportsRequestedWithLoading'
    }),

    internStatus() {
      return this.filters.dateRange.init && this.filters.dateRange.end ? 'DropoffsExport__able' : 'DropoffsExport__disable'
    },

    exportDisabled() {
      return !((this.filters.stores.length > 0 && this.filters.drivers.length > 0) || this.filters.stores.length > 0)
    },

    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

    todayParsed() {
      let today = new Date()
      return Date.parse(today)
    },

    dateRangeInit() {
      const days = 0
      const dateOffset = (24*60*60*1000) * days
      const calculatedDate = new Date()
      calculatedDate.setTime(calculatedDate.getTime() - dateOffset)
      return calculatedDate.getTime()
    },

    dateRangeEnd() {
      const calculatedDate = new Date()
      const dateNow = new Date(calculatedDate)
      return dateNow.getTime()
    },

  },

  async mounted(){
    this.clearPagination()
    this.setInitialDate()
    await this.fetchDropoffsReports()
  },

  methods:{
    ...mapActions({
      fetchDropoffReportsRequested: 'dropoff/fetchDropoffReportsRequested',
      setLoading: 'loading/setLoading',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      setError: 'error/setError'
    }),

    showDefaultErrorMessage() {
      this.setError('Ocorreu um erro ao exportar esse relatório, tente novamente ou entre em contato com o suporte.')
    },

    updateTags(){
      this.allTags = [
        ...this.filters.stores,
        ...this.filters.drivers,
        ...this.filters.orders,
        ...this.filters.sellers
      ]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.stores = this.filters.stores.filter((tag) => tag.name !== name)
      this.filters.drivers = this.filters.drivers.filter((tag) => tag.name !== name)
      this.filters.orders = this.filters.orders.filter((tag) => tag.name !== name)
      this.filters.sellers = this.filters.sellers.filter((tag) => tag.name !== name)

      this.resetSearch()
    },

    clearAllTags(){
      this.allTags = []
      this.filters.stores = []
      this.filters.drivers = []
      this.filters.orders = []
      this.filters.sellers = []

      this.resetSearch()
    },

    resetSearch() {
      this.clearPagination().then(() => this.search())
    },

    search() {
      this.updateTags()
    },

    setInitialDate() {
      this.$set(this.filters.dateRange, 'init', this.dateRangeInit)
      this.$set(this.filters.dateRange, 'end', this.dateRangeEnd)
    },

    fetchDropoffsReports(loading = true) {
      if (loading) this.setLoading(true)

      this.fetchDropoffReportsRequested({page: this.page, paginate: this.paginate}).then(() => {
        this.setPagination(this.dropoffsReportsRequested)
        if (
          JSON.stringify(this.dropoffsReportsRequested.data.content).indexOf('Andamento') > 0 &&
          this.$route.name === 'dropoffsExport'
        ) {
          if (!this.updating) {
            this.updating = true
            this.time = setTimeout(() => {
              this.fetchDropoffsReports(false)
              this.updating = false
            }, this.timeOutUpdate + this.time)
          }
        } else {
          clearTimeout(this.time)
        }
      })
    },

    idsFiltereds(name){
      if (!name) {
        return []
      }
      return this.filters[name].map(el => {
        return el?.id
      })
    },

    async exportReport(){
      this.setLoading(true)

      const obj = {
        date_start: String(this.filters.dateRange.init),
        date_end: String(this.filters.dateRange.end),
        store_ids: this.idsFiltereds('stores'),
        driver_ids: this.idsFiltereds('drivers'),
        order_numbers: this.idsFiltereds('orders'),
        seller_ids: this.idsFiltereds('sellers'),
      }

      await dropoff.exportDropoffsReport(obj, (r) => r, (e) => e, () => {
        this.setLoading(false)
        this.fetchDropoffsReports()
      })
    },

    async download(data) {
      if (data.fileName != null) {
        this.setLoading(true)
        await dropoff.downloadDropoffsReport(data.id, (r) => r, (e) => e, () => this.setLoading(false))
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.DropoffsExport {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". title ."
                         ". main .";
    grid-column-gap: 1rem;
  }
  &__Title {
    grid-area: title;
    font-weight: $font-weight-bold;
    font-style: normal;
    font-size: $font-size-mini;
    color: $color-neutral-stronger;
    margin-bottom: 20px;
  }
  &__table {
    grid-area: main;
    margin-bottom: 50px;
  }
  &__disable{
    pointer-events: none;
    opacity: .3;
  }
}
</style>
<style lang="scss">
.DropoffsExport {
  .Organizer__filter {
    align-items: flex-end;
  }
}
</style>